import React from 'react'
import Layout from '../components/Layout'
import Series from '../components/Series'
import '../styles/styles.scss'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

const annThropeSeries = () => {
  const image = useStaticQuery(graphql`
    query {
      assassin: file(relativePath: { eq: "assassin.jpg" }) {
        childImageSharp {
          fixed(width: 260) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      apprentice: file(relativePath: { eq: "apprenticebook.jpg" }) {
        childImageSharp {
          fixed(width: 260) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Series />
      <div className="annseries-container">
        <div className="synopsis">
          <p>
            She’s a master of the space-time continuum. But with alien
            mercenaries hot on her high heels, is this assassin’s life about to
            end in a black hole?
          </p>
          <p>
            Sophisticated grandma Ann Thrope is exceptionally good at murder.
            The planet’s most dangerous professional killer, she’s just
            completed lucrative hits across the cosmos. But when word of her
            remarkable work spreads through the multiverse, she’s saddled with a
            galactic-sized price on her head…
          </p>
        </div>
        <div className="books-series">
          <Link to="/assassin">
            <div className="books-in-series">
              <Img fixed={image.assassin.childImageSharp.fixed} />
            </div>
          </Link>
          <Link to="/apprentice">
            <div className="books-in-series">
              <Img fixed={image.apprentice.childImageSharp.fixed} />
            </div>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default annThropeSeries
